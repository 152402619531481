const validateContext = (context = {}) => {
  const requiredFields = [
    "storeCode",
    "currency",
    "language",
    "sizeSchema",
    "keyStoreDataversion"
  ];
  const errors = requiredFields
    .map(field =>
      typeof context[field] !== "string" || !context[field].length
        ? `context.${field} is required, and should be a non-empty string`
        : undefined
    )
    .filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }
};

export default validateContext;
