import validateItems from "./validateItems";
import validateRequestOptions from "../validateRequestOptions";
import validateGetAccessTokenDelegate from "../validateGetAccessTokenDelegate";
import { DEFAULT_LIST_ITEMS_URL_PATH } from "../constants";

/**
 * Add multiple items to saved items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {Object[]} params.items item to add to saved items. one of colourWayId or variantId is required.
 * @param {number} params.items[].productId product id
 * @param {number} [params.items[].colourWayId] colour way id
 * @param {number} [params.items[].variantId] variant id
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.ItemAddedResponse>} Promise resolving to ItemAddedResponse.
 * @throws {TypeError} only one of variantId or colourWayId should be supplied.
 * @throws {TypeError} configuration.{@link getAccessTokenDelegate|getAccessToken} is required, since this is an authorised endpoint.
 * @throws {GetAccessTokenError} error getting an access token from the getAccessToken delegate.
 * @throws {GetAccessTokenTimeoutError} timeout hit when attempting to get an access token from the getAccessToken delegate.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Add Saved Item(s)|API Documentation}
 * @example
 * sdk.addItems({ items: [{ productId: 1234543, colourWayId: 4865244 }, { productId: 1231334, variantId: 223433 }] });
 */
const addItems = (
  { api, context: queryParams, configuration: { getAccessToken } = {} },
  { items, requestOptions } = {}
) => {
  validateGetAccessTokenDelegate(getAccessToken);
  validateItems(items);
  validateRequestOptions(requestOptions);
  return api.post({
    path: DEFAULT_LIST_ITEMS_URL_PATH,
    body: { items },
    queryParams,
    requestOptions
  });
};

export default addItems;
