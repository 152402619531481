import emitStartedEvent from "./emitStartedEvent";

const applyOperationStartedEvent = ({ configuration: { eventBus } }) => (
  operation,
  operationName
) => (...args) => {
  if (eventBus !== undefined) {
    emitStartedEvent({ eventBus, operationName, args });
  }

  return operation(...args);
};

export default applyOperationStartedEvent;
