import configureRequest from "./configureRequest";
import { authorisation, contentType } from "./headers";
import urlFactoryProvider from "./urlFactoryProvider";
import mapObjectValues from "../mapObjectValues";

const apiFactory = ({
  configuration: {
    getAccessToken,
    getAccessTokenTimeout,
    apiOrigin,
    interactionCallback = () => {},
    logger,
    serverRequestLogger,
    retries,
    retryDelay
  }
}) => {
  const urlFactory = urlFactoryProvider({ apiOrigin });
  const { get, post, delete: del, patch } = configureRequest({
    logger,
    serverRequestLogger,
    retries,
    retryDelay
  });

  return mapObjectValues(
    { get, post, delete: del, patch },
    method => async ({
      path,
      queryParams: params,
      body,
      requestOptions,
      isAuthRequired = true
    }) => {
      const result = await method({
        url: urlFactory(path),
        params,
        body,
        headers: {
          ...contentType,
          ...(isAuthRequired
            ? await authorisation({ getAccessToken, getAccessTokenTimeout })
            : {})
        },
        ...requestOptions
      });
      interactionCallback();
      return result;
    }
  );
};

export default apiFactory;
