import { configureRequest as configureWebRequest } from "asos-web-request";
import { name, version } from "../../package.json";

const configureRequest = ({
  logger,
  serverRequestLogger,
  retries,
  retryDelay
}) =>
  configureWebRequest({
    isServer: SERVER,
    logger,
    serverRequestLogger,
    defaultTimeout: 10000,
    retries,
    retryDelay,
    client: {
      name,
      version
    }
  });

export default configureRequest;
