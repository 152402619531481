import { version } from "../../package.json";
import operations from "../operations";
import api from "../api";
import validateParams from "./validateParams";
import mapObjectValues from "../mapObjectValues";
import mapContext from "./mapContext";
import applyOperationStartedEvent from "./applyOperationStartedEvent";

/**
 * Saved Items SDK factory function
 * @memberof asos.customer.savedItemsSdk
 * @function
 * @param {Object} params
 * @param {Object} params.context customer context under which API requests are made
 * @param {string} params.context.storeCode storeCode to pass to the API
 * @param {string} params.context.currency currency code to pass to the API
 * @param {string} params.context.language IETF RFC 5646 language code to pass to the API
 * @param {string} params.context.sizeSchema size schema to pass to the API
 * @param {string} params.context.keyStoreDataversion keyStoreDataversion to pass to the API
 * @param {Object} params.configuration environment configuration and dependencies
 * @param {getAccessTokenDelegate} [params.configuration.getAccessToken] function that resolves a customer access token used to create a Bearer token when authorising with the API. Operations requiring authentication will fail if this is not provided.
 * @param {number} [params.configuration.getAccessTokenTimeout=8000] timeout in ms for the get access token delegate to resolve.  Will throw a {@link GetAccessTokenError} with "timeout" as a reason if exceeded.  Default of 8s chosen as worst-case time identity have spotted during gigya outages.
 * @param {string} [params.configuration.apiOrigin={window.location.origin|https://api.asos.com}] api origin. defaults to {window.location.origin} in browser bundle and https://api.asos.com in server bundle. Calls will be made to "{apiOrigin}/api/" in browser bundle and "{apiOrigin}" in server bundle.
 * @param {function} [params.configuration.interactionCallback] a callback to run after each API request. Can be used to plug in the {@link https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/spa-save|New Relic Browser Agent SPA API}
 * @param {external:asos-web-logging.logger} [params.configuration.logger] instance of {@link https://github.com/asosteam/asos-web-logging|asos-web-logging}. Required when running server-side
 * @param {function} [params.configuration.serverRequestLogger] a function that will be passed request stats when running server-side. It is intended to be used for external tools such as newRelic. It should take a single object as an argument.
 * @param {number} [params.configuration.retries] number of retries to attempt, up to a timeout (if specified) in the {@link requestOptions}.
 * @param {retryDelayDelegate} [params.configuration.retryDelay] a function that can determine how many ms to wait between retrying requests.
 * @param {eventBus} [params.configuration.eventBus] an event bus on which operation events will be emitted. It is intended to be used for tracking tools such as Adobe Analytics or Optimisely.
 * @returns {module:sdkInstance}
 * @throws {TypeError} configuration.getAccessToken should be a function if defined
 * @throws {TypeError} configuration.apiOrigin should be a string if defined
 * @throws {TypeError} configuration.logger is required, and should be an instance of asos-web-logging
 * @throws {TypeError} configuration.serverRequestLogger should be a function if defined
 * @throws {TypeError} configuration.interactionCallback should be a function if defined
 * @throws {TypeError} configuration.retries should be a number if defined
 * @throws {TypeError} configuration.retryDelay should be a function if defined
 * @throws {TypeError} configuration.getAccessToken should be a function if defined
 * @throws {TypeError} configuration.getAccessTokenTimeout should be a number if defined
 * @throws {TypeError} configuration.eventBus should be an eventBus instance if defined
 * @throws {TypeError} context.storeCode is required, and should be a non-empty string
 * @throws {TypeError} context.currency is required, and should be a non-empty string
 * @throws {TypeError} context.language is required, and should be a non-empty string
 * @throws {TypeError} context.sizeSchema is required, and should be a non-empty string
 * @throws {TypeError} context.keyStoreDataversion is required, and should be a non-empty string
 * @example
 * sdkInstance = sdk({
 *   context: {
 *     storeCode: "COM",
 *     currency: "GBP",
 *     language: "en-GB",
 *     sizeSchema: "UK",
 *     keyStoreDataversion: "p1swt7e-15"
 *   },
 *   configuration: {
 *     getAccessToken: () => "testAccessToken",
 *     interactionCallback: window.newrelic.interaction().save,
 *     eventBus: window.asos.eventBus
 *   }
 * });
 */
const sdk = params => {
  validateParams(params);
  const { context, configuration } = params;
  const configuredApi = api({ configuration });
  const mappedContext = mapContext(context);

  const apiBoundOperations = mapObjectValues(operations, operation =>
    operation.bind(operation, {
      api: configuredApi,
      context: mappedContext,
      configuration
    })
  );

  const eventDecoratedOperations = mapObjectValues(
    apiBoundOperations,
    applyOperationStartedEvent({ configuration })
  );

  /**
   * @module sdkInstance
   **/
  return {
    /**
     * @property {string} version version of the SDK
     */
    version,
    ...eventDecoratedOperations
  };
};

export default sdk;
